@import 'assets/fonts/circular-font/style.css';
body {
  margin: 0;
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: none;
}
body * {
  font-variant: normal !important;
  font-feature-settings: normal !important;
}
@page {
  size: auto;
  margin: 0mm;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-tooltip-inner {
  font-family: 'Circular Std', sans-serif;
}
.ant-select-item-option-content {
  padding: 8px 4px;
  font-size: 14px;
  font-weight: 500;
}
.ant-select-item-option {
  margin: 4px 8px;
  border-radius: 6px;
}
.ant-select-item-option-selected,
.ant-select-item-option-active {
  background-color: #f9f9f9 !important;
}
.rc-virtual-list-scrollbar {
  background-color: #dfdfe1 !important;
  border-radius: 4px !important;
  margin-right: 4px;
}
.rc-virtual-list-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #ee223b !important;
}
.ant-drawer-content-wrapper {
  max-height: 80%;
  overflow: auto;
}
.ant-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9e9e9e;
}
.ant-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9e9e9e;
}
.ant-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #9e9e9e;
}
.ant-input:-moz-placeholder {
  /* Firefox 18- */
  color: #9e9e9e;
}
.ant-picker-input > input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9e9e9e;
}
.ant-picker-input > input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9e9e9e;
}
.ant-picker-input > input:-ms-input-placeholder {
  /* IE 10+ */
  color: #9e9e9e;
}
.ant-picker-input > input:-moz-placeholder {
  /* Firefox 18- */
  color: #9e9e9e;
}
.ant-select-item-option-content {
  white-space: normal;
}
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1040;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ant-modal-mask::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar {
  display: none;
}

.rbc-events-container > div {
  //transition: top 0.5s;
}

.rbc-events-container > .rbc-addons-dnd-drag-preview {
  transition: top 0.3s;
}
